import Vuetify from "vuetify/lib";
import ToastBox from "./MToast";

const Toast = {};

Toast.install = (Vue) => {
  const ToastBoxContructor = Vue.extend(ToastBox);
  const instance = new ToastBoxContructor();
  instance.$vuetify = new Vuetify().framework;
  instance.$mount(document.createElement("div"));
  document.body.appendChild(instance.$el);

  const fire = (type, message, solution, duration) => {
    instance.message = message;
    instance.solution = solution;
    instance.show = true;
    instance.type = type;
    instance.duration = duration;
    // setTimeout(() => {
    //   instance.show = false
    // }, duration)
  };

  Vue.prototype.$toast = {};
  Vue.prototype.$toast.success = (message, solution = "", duration = 3000) => {
    fire("success", message, solution, duration);
  };

  Vue.prototype.$toast.info = (message, solution = "", duration = 3000) => {
    fire("info", message, solution, duration);
  };

  Vue.prototype.$toast.error = (message, solution, duration = 3000) => {
    fire("error", message, solution, duration);
  };

  Vue.prototype.$toast.warning = (message, solution, duration = 3000) => {
    fire("warning", message, solution, duration);
  };
};

export default Toast;
