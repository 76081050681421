import vuetify from "@/plugins/vuetify";

export default {
  // 统一控制报表 loading 的效果
  buildLoadingOpts() {
    return {
      text: "Loading... Please wait",
      color: textColor(), // 旋转动画的颜色
      textColor: textColor(),
      maskColor: maskColor(0.8),
      zlevel: 1,
      fontSize: 20,
      fontWeight: "800",
      fontFamily: "nunitosans",
    };
  },

  // 统一控制报表 no data 的效果
  buildNoDataOpts() {
    return {
      text: "No data available",
      textColor: textColor(),
      maskColor: maskColor(1), // 完全不透明
      zlevel: 1,
      showSpinner: false, // 是否显示旋转动画（spinner）
      fontSize: 20,
      fontWeight: "800",
      fontFamily: "nunitosans",
    };
  },

  grid() {
    return {
      top: "47",
      left: "62",
      right: "62",
      bottom: "80",
      containLabel: true,
    };
  },

  chartColors() {
    return ["#FF707E", "#07BAE2", "#32D994", "#FBC62E", "#9F5BFF", "#EE6C4D", "#854D26", "#17C8B3", "#9A348E", "#326DA9"];
  },
  chartColor(index) {
    const currentColors = ["#FF707E", "#07BAE2", "#32D994", "#FBC62E", "#9F5BFF", "#EE6C4D", "#854D26", "#17C8B3", "#9A348E", "#326DA9"];
    return currentColors[index % currentColors.length];
  },

  areaStyle(echarts, color) {
    return {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
        {
          offset: 0,
          color: this.hexToRgba(color, 0),
        },
        {
          offset: 1,
          color: this.hexToRgba(color, 1),
        },
      ]),
    };
  },
  hexToRgba(hex, alpha) {
    // 去除井号 (#) 符号
    hex = hex.replace(/^#/, "");

    // 处理 3 位和 6 位的 hex 颜色
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map(function (h) {
          return h + h; // 将 3 位转换为 6 位
        })
        .join("");
    }
    // 解析 RGBA 值
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255; // 获取红色值
    const g = (bigint >> 8) & 255; // 获取绿色值
    const b = bigint & 255; // 获取蓝色值

    // 返回 RGBA 字符串
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  },
};

function textColor() {
  if (vuetify.framework.theme.dark) {
    return "rgba(255, 255, 255, 0.6)";
  } else {
    return "rgba(0, 0, 0, 0.5)";
  }
}

function maskColor(alpha) {
  if (vuetify.framework.theme.dark) {
    return "rgba(30, 30, 30, " + alpha + ")";
  } else {
    return "rgba(255, 255, 255, " + alpha + ")";
  }
}
