import http from "@/utils/http/index.js";
import store from "@/store";

export default {
  resetUsedBy: (param) => {
    return http.$p("/ads/credential/reset-used-by", param);
  },
  credentialList: (param) => {
    return http.$p("/ads/credential/list", param);
  },
  myCredentialList: (param) => {
    return http.$p("/ads/credential/my-credential-list", param);
  },
  myCredentialPage: (param) => {
    return http.$p("/ads/credential/page-my-credential", param);
  },
  credentialDetail: (param) => {
    return http.$p("/ads/credential/ads-credential", param);
  },
  credentialModify: (param) => {
    return http.$p("/ads/credential/modify-title", param);
  },
  credentialDelete: (param) => {
    return http.$p("/ads/credential/delete", param);
  },

  accountList: (param) => {
    return http.$p("/ads/credential/account-list", param);
  },

  loginFacebook: () => {
    return new Promise((resolve, reject) => {
      let scope = "business_management, ads_read, read_insights";
      window.FB.login(
        (response) => {
          if (response.status !== "connected") {
            resolve({ code: "EAAU-9999", message: "Not logged in Facebook.", solution: "Please try again.", data: response });
            return;
          }

          let scopeArray = scope
            .split(",")
            .map((item) => item.trim())
            .filter((item) => !!item);
          facebookSubmitAuth({ ...response.authResponse, scopes: scopeArray })
            .then((respData) => {
              resolve(respData);
            })
            .catch((err) => {
              reject(err);
            });
        },
        { scope: scope }
      );
    });
  },
  facebookSubmitAuth: facebookSubmitAuth,

  googleAuthConfig: () => {
    return http.$p("/ads/google/auth/config", {});
  },
  googleAuth: (clientId, redirectUri, callbackUri, dataSourceType) => {
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

    var form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", oauth2Endpoint);

    let user = store.state.user;
    var stateParam = {
      tv: user.info.tokenValue,
      tk: user.companyInfo.tenantKey,
      ru: callbackUri,
      ds: dataSourceType,
    };

    var params = {
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: "code",
      scope: "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
      access_type: "offline",
      include_granted_scopes: "true",
      state: JSON.stringify(stateParam),
      prompt: "consent",
    };

    for (var p in params) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  },

  lazadaAuthConfig: () => {
    return http.$p("/ads/lazada/auth/config", {});
  },
  lazadaAuth: (clientId, redirectUri, callbackUri, dataSourceType) => {
    var endpoint = "https://auth.lazada.com/oauth/authorize";

    var form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", endpoint);

    let user = store.state.user;
    var stateParam = {
      tv: user.info.tokenValue,
      tk: user.companyInfo.tenantKey,
      ru: callbackUri,
      ds: dataSourceType,
    };

    var params = {
      response_type: "code",
      force_auth: true,
      redirect_uri: redirectUri,
      client_id: clientId,
      state: JSON.stringify(stateParam),
    };

    for (var p in params) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  },
};

function facebookSubmitAuth(param) {
  return http.$p("/ads/facebook/auth/submit", param);
}
