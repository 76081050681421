import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import Vuetify from "vuetify/lib";
Vuetify.config.silent = true;

import i18n from "../i18n/index.js";

import bellIcon from "@/assets/icons/bell.svg";
import creditCardIcon from "@/assets/icons/credit-card.svg";
import homeIcon from "../assets/icons/home.svg";
import menuIcon from "@/assets/icons/menu.svg";
import plugInIcon from "@/assets/icons/plug-in.svg";
import usersIcon from "@/assets/icons/users.svg";

Vue.use(Vuetify);

const opts = {
  lang: {
    t: (key, ...params) => i18n.t(key, params), // 设置Vuetify使用vue-i18n进行翻译
  },
  theme: {
    dark: false, // 初始模式（明亮/暗黑）
    themes: {
      light: {
        primary: "#FFD65C",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
      dark: {
        primary: "#FFD65C",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      iconBell: {
        component: bellIcon,
      },
      iconHome: {
        component: homeIcon,
      },
      "icon-credit-card": {
        component: creditCardIcon,
      },
      iconMenu: {
        component: menuIcon,
      },
      iconPlugIn: {
        component: plugInIcon,
      },
      iconUsers: {
        component: usersIcon,
      },
    },
  },
};

export default new Vuetify(opts);
