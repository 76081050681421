import http from "@/utils/http/index.js";
import store from "@/store";
export default {
  login: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/user/login", param)
        .then((res) => {
          if (res && (res.code === "IAAO-0000" || res.code === "IULS-0100")) {
            //二次验证也需要保存token信息
            store.dispatch("user/login", res.data);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      http
        .$p("/user/logout", {})
        .then((res) => {
          store.dispatch("user/logout");
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
  listCompanies: () => {
    return http.$p("/user/list-companies", {});
  },
  loginCompany: (param) => {
    return http.$p("/user/login-company", param);
  },
  info: () => {
    return new Promise((resolve, reject) => {
      http
        .$p("/user/info", {})
        .then((res) => {
          if (res && res.code === "IAAO-0000") {
            // 更新store的信息
            let userInfo = store.state.user.info;
            userInfo = Object.assign(userInfo, res.data);
            store.dispatch("user/login", userInfo);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  modPw: (param) => {
    return http.$p("/user/mod-pw", param);
  },
  modInfo: (param) => {
    return http.$p("/user/mod-info", param);
  },
  login2ndAuth: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/user/login-2nd-auth", param)
        .then((res) => {
          if (res && res.code === "IAAO-0000") {
            //二次验证也提前保存token信息
            store.dispatch("user/login", res.data);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  register: (parma) => {
    return http.$p("/user/register", parma);
  },
  uploadAvatar: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/avatar/upload-images", param, headers);
  },
};
