<template>
  <v-snackbar v-model="show" :timeout="duration" :color="getBorderColor" multi-line right top outlined text>
    <div class="flex-row">
      <div>
        <v-img max-width="24" max-height="24" contain eager :src="getImg"></v-img>
      </div>
      <div class="flex-column">
        <div class="font-base-semi" style="color: var(--Base-C-Text-Label-1, #262626)">{{ message }}</div>
        <div class="font-sm-regular" style="color: var(--Base-C-Text-Label-2, #585858)">{{ solution }}</div>
      </div>
      <v-spacer></v-spacer>
      <div>
        <!-- 
          <v-btn @click="show = false" text>
           <span class="font-sm-regular" style="color: var(--Base-C-Text-Label-2, #585858)">Close</span> 
          </v-btn>-->
      </div>
      <div>
        <v-img max-width="10" max-height="10" src="@/assets/img/mape/svg/close.svg" @click="show = false"></v-img>
      </div>
    </div>
  </v-snackbar>
</template>
<script>
export default {
  name: "MToast",

  data() {
    return {
      show: false,
      message: "",
      solution: "",
      type: "success",
      duration: 3000,
      color: "",

      cMap: {
        success: {
          bgColor: "var(--Light-Sucess-background, #f6fff9)",
          borderColor: "var(--Additional-C-Success-Border, #89d1af)",
          img: require(`@/assets/img/mape/svg/messages-success.svg`),
        },
        warning: {
          bgColor: "var(--Brand-C-Primary-Bg, #fffaeb)",
          borderColor: "var(--Additional-C-Warning-Main, #faad14)",
          img: require(`@/assets/img/mape/svg/messages-warning.svg`),
        },
        info: {
          bgColor: "var(--Additional-C-Info-Bg, #e4f2ff)",
          borderColor: "var(--Additional-C-Info-Active, #156bf4)",
          img: require(`@/assets/img/mape/svg/messages-info.svg`),
        },
        error: {
          bgColor: "var(--Additional-C-Error-Bg, #ffebee)",
          borderColor: "var(--Additional-C-Error-Text, #f53d3d)",
          img: require(`@/assets/img/mape/svg/messages-error.svg`),
        },
        default: {
          class: "",
          color: "",
          img: "",
        },
      },
    };
  },
  computed: {
    getImg() {
      return `${this.getMap(this.type, "img")}`;
    },
    getBorderColor() {
      return `${this.getMap(this.type, "borderColor")}`;
    },
  },

  methods: {
    getMap(type, mode) {
      if (typeof type !== "string") {
        console.error("Invalid type value:", type);
        return "";
      }
      return this.cMap[type] ? this.cMap[type][mode] : this.cMap.default[mode];
    },
    showToast(type, message, solution, duration) {
      this.show = true;
      this.type = type;
      this.message = message;
      this.solution = solution;
      this.duration = duration || 3000;
    },
  },
};
</script>
<style scoped>
.flex-row {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 16px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
</style>
